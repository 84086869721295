const SelectSearchable = {
  elContainer: '.searchable',
  elSelect: '.searchable select',
  elOptions: 'select option',
  elInput: '.searchable input',
  elLi: '.searchable ul li',
  init: function() {
    if ($(this.elContainer).length < 1) { return; }

    this.setup();
    this.keyupSetting();
    this.focusSetting();
    this.blurSetting();
    this.clickSetting();
    this.hoverSetting();
  },
  setup: function() {
    $(this.elSelect).css('display', 'none');

    this.appendOptions();
  },
  appendOptions: function() {
    let that = this;

    $(this.elSelect).css('display', 'none');

    $(this.elContainer).each(function(_i, el) {
      let elUl = $(el).append('<ul></ul>').find('ul');
      let selectedText = $(el).find(':selected').text();
      let selectedVal = $(el).find(':selected').val();
      let elInput = $('<input>').attr({
                      type: 'text',
                      value: selectedText.trim()
                    });
      let elOptions = $(el).find(that.elOptions)

      $(el).prepend(elInput);

      $.map(elOptions, function(option) {
        let clSelected = selectedVal === $(option).val() ? 'selected' : '';

        $(elUl).append("<li class='"+ clSelected +"' data-val="+ $(option).val() + ">" + $(option).text() + "</li>");
      });
    });
  },
  keyupSetting: function() {
    let that = this;

    $(document).on('keyup', this.elInput, function(e) {
      that.filterFunction(this, e);
    })
  },
  filterFunction: function(that, event) {
    let container, li, input_val;

    container = $(that).closest(this.elContainer);
    input_val = container.find('input').val().toUpperCase();

    if (['ArrowDown', 'ArrowUp', 'Enter'].indexOf(event.key) != -1) {
      this.keyControl(event, container)
    } else {
      li = container.find('ul li');
      li.each(function (i, obj) {
        if ($(this).text().toUpperCase().indexOf(input_val) > -1) {
          $(this).show();
        } else {
          $(this).hide();
        }
      });

      container.find('ul li').removeClass('selected');
      setTimeout(function () {
        container.find('ul li:visible').first().addClass('selected');
      }, 100)
    }
  },
  keyControl: function (e, container) {
    if (e.key == 'ArrowDown') {
      if (container.find('ul li').hasClass('selected')) {
        if (container.find('ul li:visible').index(container.find('ul li.selected')) + 1 < container.find('ul li:visible').length) {
          container.find('ul li.selected').removeClass('selected').nextAll().not("[style*='display: none']").first().addClass('selected');
        }
      } else {
        container.find('ul li:first-child').addClass('selected');
      }
    } else if (e.key == 'ArrowUp') {
      if (container.find('ul li:visible').index(container.find('ul li.selected')) > 0) {
        container.find('ul li.selected').removeClass('selected').prevAll().not("[style*='display: none']").first().addClass("selected");
      }
    } else if (e.key == 'Enter') {
      container.find('input').val(container.find('ul li.selected').text().trim()).blur();
      this.onSelectEl(container.find('ul li.selected'));
    }

    container.find('ul li.selected')[0].scrollIntoView({
      behavior: 'smooth',
    });
  },
  onSelectEl: function(el) {
    let elSelect = $(el).closest(this.elContainer).find('select');

    elSelect.val(el.data('val'));

    if (elSelect.hasClass('submit-onchange')) { $(el).closest('form').submit(); }
  },
  focusSetting: function() {
    let that = this;

    $(document).on('focus', that.elInput, function () {
      $(this).select();
      $(this).closest(that.elContainer).find('ul').show();
      $(this).closest(that.elContainer).find('ul li').show();
    });
  },
  blurSetting: function() {
    let that = this;

    $(document).on('blur', that.elInput, function () {
      let there = this;

      setTimeout(function () {
        $(there).closest(that.elContainer).find('ul').hide();
      }, 300);
    });
  },
  clickSetting: function() {
    let that = this;

    $(document).on('click', that.elLi, function () {
      $(this).closest(that.elContainer).find('input').val($(this).text().trim()).blur();
      that.changeLiClassSelected(this);
      that.onSelectEl($(this));
    });
  },
  hoverSetting: function() {
    let that = this;

    $(that.elLi).hover(function () {
      that.changeLiClassSelected(this);
    });
  },
  changeLiClassSelected: function(el) {
    $(el).closest(this.elContainer).find('ul li.selected').removeClass('selected');
    $(el).addClass('selected');
  }
}

export default SelectSearchable;
